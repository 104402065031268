<template>
  <div>
    <div v-if="action == 'next'">
      <div>
        <v-btn
          v-if="supplier.status == 0"
          @click="isVisible = true"
          class="hover:tw-opacity-80 !tw-text-white !tw-bg-custom-blue tw-cursor-pointer"
          >Richiedi preventivo</v-btn
        >
        <v-chip
          v-else
          @click="isVisible = true"
          :class="`!tw-text-white quotation_${supplier.salted_id}`"
          :color="_mapQuotationStatus(supplier).color"
          >{{ _mapQuotationStatus(supplier).label }}</v-chip
        >
        <v-dialog
          max-height="80%"
          content-class="!tw-overflow-y-hidden"
          scrollable
          v-model="isVisible"
          persistent
          max-width="1200px"
        >
          <div v-if="!isLoading" class="tw-flex tw-bg-white">
            <v-card class="tw-w-1/2 !tw-overflow-y-scroll tw-flex tw-flex-col">
              <v-card-title> </v-card-title>
              <v-card-text>
                <div class="tw-flex tw-w-full tw-justify-between">
                  <h3 class="tw-text-3xl tw-font-bold !tw-text-dark">
                    {{ getDialogTitle(supplier.status) }}
                  </h3>
                  <a
                    href="mailto:jacopo@icaimballaggi.it?subject=Feedback%20ICA%20Progest&body=Scrivi qua il problema riscontrato"
                  >
                    <v-btn
                      class="!tw-bg-azure !tw-text-dark hover:!tw-opacity-80"
                      >Qualcosa non va?
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </a>
                </div>
              </v-card-text>

              <div class="tw-px-6">
                <div class="tw-text-xl">
                  Stampatore: {{ quotation.machine_supplier }}
                </div>
                <div
                  v-if="user.role != 'supplier' && supplier.status == 0"
                  class="tw-text-xl"
                >
                  Fornitore: {{ supplier.supplier }}
                </div>
                <div v-if="quotation.rif_code" class="tw-text-xl">
                  {{quotation.type == 'preventivi_stampa' ? 'Codice commessa' : 'Codice fornitore'}}: {{ quotation.rif_code }}
                </div>
                <div class="tw-text-xl">{{quotation.type == 'preventivi_stampa' ? 'Materiale' : 'Macchina'}}: {{ quotation.tp }}</div>
                <div class="tw-text-xl">Resa: {{ quotation.resa }}</div>
                <div v-if="supplier.price" class="tw-text-xl">
                  Prezzo: €{{ supplier.price }}
                </div>

                <div v-if="supplier.status == 0">
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Seleziona i file:
                  </div>
                  <div v-if="files.length == 0">
                    Non ci sono file da selezionare, caricali negli allegati
                  </div>
                  <div>
                    <div class="tw-mt-4 tw-mb-2">
                      Seleziona se caricare un file oppure utilizzare un link di
                      un provider esterno
                    </div>
                    <v-radio-group v-model="fileType">
                      <v-radio
                        v-for="(file_type, n) in ['link', 'file']"
                        :key="n"
                        :label="`${file_type}`"
                        :value="file_type"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <div v-if="fileType == 'file'">
                    <FileItem
                      @file-selected="handleFileSelection"
                      :_id="_id"
                      v-for="(file, index) in files"
                      :key="index"
                      :fname="file"
                      hasSelection="true"
                      path="ica"
                    />
                  </div>
                  <v-combobox
                    v-else
                    v-model="fileLinks"
                    hide-selected
                    hint="Inserisci il link e premi invio per inserirlo"
                    label="Aggiungi link"
                    multiple
                    persistent-hint
                    small-chips
                    @input="checkLinkValidity"
                  >
                  </v-combobox>
                </div>

                <div v-if="supplier.status == 1">
                  <div
                    v-if="supplier.icaFiles.length > 0"
                    class="tw-text-xl tw-mt-4 tw-mb-2"
                  >
                    Master preventivo:
                  </div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, index) in supplier.icaFiles"
                    :key="index"
                    :fname="file"
                    path="ica"
                  />
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Inserisci prezzo:
                  </div>
                  <v-text-field
                    :disabled="!isActionEnabled"
                    type="number"
                    filled
                    v-model="supplier.price"
                  ></v-text-field>
                </div>

                <div v-if="supplier.status == 2">
                  <div
                    v-if="supplier.icaFiles.length > 0"
                    class="tw-text-xl tw-mt-4 tw-mb-2"
                  >
                    Master Preventivo:
                  </div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, index) in supplier.icaFiles"
                    :key="index"
                    :fname="file"
                    path="ica"
                  />
                  <div>
                    <div>
                      <div class="tw-mt-4 tw-mb-2">
                        Seleziona se caricare un file oppure utilizzare un link
                        di un provider esterno
                      </div>
                      <v-radio-group :disabled="!isActionEnabled" v-model="fileType">
                        <v-radio
                          v-for="(file_type, n) in ['link', 'file']"
                          :key="n"
                          :label="`${file_type}`"
                          :value="file_type"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div v-if="fileType == 'file'">
                      <v-file-input
                        :disabled="!isActionEnabled"
                        ref="fileInput"
                        small-chips
                        @change="handleFileChange"
                        :rules="rules"
                        required
                        multiple
                        filled
                        label="Carica file definitivi"
                      >
                      </v-file-input>
                      <v-combobox
                        :disabled="!isActionEnabled"
                        readonly
                        deletable-chips
                        disable-lookup
                        small-chips
                        v-model="filesListString"
                        multiple
                        no-data-text="Nessun file caricato"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip :key="index" small>
                            {{ item }}

                            <v-icon
                              class="!tw-bg-custom-blue tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                              @click="viewFile(item)"
                              small
                              >mdi-eye</v-icon
                            >
                            <v-icon
                              class="!tw-bg-dark tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                              @click="onInput(item)"
                              small
                              >mdi-close</v-icon
                            >
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>
                    <v-combobox
                      v-else
                      :disabled="!isActionEnabled"
                      v-model="fileLinks"
                      hide-selected
                      hint="Inserisci il link e premi invio per inserirlo"
                      label="Aggiungi link"
                      multiple
                      persistent-hint
                      small-chips
                      @input="checkLinkValidity"
                    >
                    </v-combobox>
                  </div>
                </div>

                <div v-if="supplier.status == 3">
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati:</div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, index) in supplier.defFiles"
                    :key="index"
                    :fname="file"
                    path="quotation"
                  />

                  <div v-if="supplier.draftFiles.length == 0">
                    <div class="tw-mt-4 tw-mb-2">
                      Per inviare la bozza seleziona se caricare un file oppure
                      utilizzare un link di un provider esterno
                    </div>
                    <v-radio-group :disabled="!isActionEnabled" v-model="fileType">
                      <v-radio
                        v-for="(file_type, n) in ['link', 'file']"
                        :key="n"
                        :label="`${file_type}`"
                        :value="file_type"
                      ></v-radio>
                    </v-radio-group>
                  </div>

                  <div class="tw-text-lg tw-font-bold" v-else>
                    Bozza caricata:
                  </div>

                  <div
                    v-if="fileType == 'file' && supplier.draftFiles.length == 0"
                  >
                    <v-file-input
                      :disabled="!isActionEnabled"
                      ref="fileInput"
                      small-chips
                      @change="handleFileChange"
                      :rules="rules"
                      required
                      multiple
                      filled
                      label="Carica file definitivi"
                    >
                    </v-file-input>
                    <v-combobox
                      :disabled="!isActionEnabled"
                      readonly
                      deletable-chips
                      disable-lookup
                      small-chips
                      v-model="filesListString"
                      multiple
                      no-data-text="Nessun file caricato"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip :key="index" small>
                          {{ item }}

                          <v-icon
                            class="!tw-bg-custom-blue tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                            @click="viewFile(item)"
                            small
                            >mdi-eye</v-icon
                          >
                          <v-icon
                            class="!tw-bg-dark tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                            @click="onInput(item)"
                            small
                            >mdi-close</v-icon
                          >
                        </v-chip>
                      </template>
                    </v-combobox>
                  </div>
                  <v-combobox
                    v-else-if="
                      fileType == 'link' && supplier.draftFiles.length == 0
                    "
                    v-model="fileLinks"
                    hide-selected
                    hint="Inserisci il link e premi invio per inserirlo"
                    label="Aggiungi link"
                    multiple
                    persistent-hint
                    small-chips
                    @input="checkLinkValidity"
                    :disabled="!isActionEnabled"
                  >
                  </v-combobox>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, j) in supplier.draftFiles"
                    :key="j + files.length"
                    :fname="file"
                    path="quotation"
                  />
                  <div v-if="supplier._type == 'order'">
                    <div class="tw-text-xl tw-mt-4 tw-mb-2">
                      Inserisci prezzo:
                    </div>
                    <v-text-field
                      type="number"
                      filled
                      v-model="supplier.price"
                      :disabled="!isActionEnabled"
                    ></v-text-field>
                  </div>
                </div>

                <div v-if="supplier.status == 4">
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Ica:</div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, i) in supplier.defFiles"
                    :key="i"
                    :fname="file"
                    path="quotation"
                  />
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Allegati {{ supplier.supplier }}:
                  </div>
                  <div v-if="_draftFiles.length == 0">
                    Non ci sono file da visualizzare
                  </div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, j) in _draftFiles"
                    :key="j + files.length"
                    :fname="file"
                    path="quotation"
                  />
                </div>

                <div v-if="supplier.status == 5">
                  <div class="tw-mt-4 tw-mb-2">Carica il DDT</div>
                  <div v-if="fileType == 'file'">
                    <v-file-input
                      ref="fileInput"
                      small-chips
                      @change="handleFileChange"
                      :rules="rules"
                      required
                      multiple
                      filled
                      label="Carica file definitivi"
                    >
                    </v-file-input>
                    <v-combobox
                    :disabled="!isActionEnabled"
                      readonly
                      deletable-chips
                      disable-lookup
                      small-chips
                      v-model="filesListString"
                      multiple
                      no-data-text="Nessun file caricato"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip :key="index" small>
                          {{ item }}

                          <v-icon
                            class="!tw-bg-custom-blue tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                            @click="viewFile(item)"
                            small
                            >mdi-eye</v-icon
                          >
                          <v-icon
                            class="!tw-bg-dark tw-rounded-full tw-p-[1px] tw-ml-2 !tw-bg-opacity-70 !tw-text-white"
                            @click="onInput(item)"
                            small
                            >mdi-close</v-icon
                          >
                        </v-chip>
                      </template>
                    </v-combobox>
                  </div>
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Ica:</div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, i) in supplier.defFiles"
                    :key="i"
                    :fname="file"
                    path="quotation"
                  />
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Allegati {{ supplier.supplier }}:
                  </div>
                  <div v-if="_draftFiles.length == 0">
                    Non ci sono file da visualizzare
                  </div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, j) in _draftFiles"
                    :key="j + files.length"
                    :fname="file"
                    path="quotation"
                  />
                </div>

                <div v-if="supplier.status == 6">
                  <div class="tw-text-xl tw-mt-4 tw-mb-4">
                    DDT:
                    <FileItem
                      @file-selected="handleFileSelection"
                      :_id="_id"
                      v-for="(file, i) in supplier.ddtFiles"
                      :key="i"
                      :fname="file"
                      path="quotation"
                    />
                  </div>
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Ica:</div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, i) in supplier.defFiles"
                    :key="i"
                    :fname="file"
                    path="quotation"
                  />
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Allegati {{ supplier.supplier }}:
                  </div>
                  <div v-if="_draftFiles.length == 0">
                    Non ci sono file da visualizzare
                  </div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, j) in _draftFiles"
                    :key="j + files.length"
                    :fname="file"
                    path="quotation"
                  />
                </div>

                <div v-if="supplier.status == 7">
                  <div class="tw-text-xl tw-mt-4 tw-mb-4">
                    DDT:
                    <FileItem
                      @file-selected="handleFileSelection"
                      :_id="_id"
                      v-for="(file, i) in supplier.ddtFiles"
                      :key="i"
                      :fname="file"
                      path="quotation"
                    />
                  </div>
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Ica:</div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, i) in supplier.defFiles"
                    :key="i"
                    :fname="file"
                    path="quotation"
                  />
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Allegati {{ supplier.supplier }}:
                  </div>
                  <div v-if="_draftFiles.length == 0">
                    Non ci sono file da visualizzare
                  </div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, j) in _draftFiles"
                    :key="j + files.length"
                    :fname="file"
                    path="quotation"
                  />
                </div>

                <div v-if="supplier.status == 8">
                  <div class="tw-text-xl tw-mt-4 tw-mb-4">
                    DDT:
                    <FileItem
                      @file-selected="handleFileSelection"
                      :_id="_id"
                      v-for="(file, i) in supplier.ddtFiles"
                      :key="i"
                      :fname="file"
                      path="quotation"
                    />
                  </div>
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">Allegati Ica:</div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, i) in supplier.defFiles"
                    :key="i"
                    :fname="file"
                    path="quotation"
                  />
                  <div class="tw-text-xl tw-mt-4 tw-mb-2">
                    Allegati {{ supplier.supplier }}:
                  </div>
                  <div v-if="_draftFiles.length == 0">
                    Non ci sono file da visualizzare
                  </div>
                  <FileItem
                    @file-selected="handleFileSelection"
                    :_id="_id"
                    v-for="(file, j) in _draftFiles"
                    :key="j + files.length"
                    :fname="file"
                    path="quotation"
                  />
                </div>

                <div
                  v-if="supplier.status != 8"
                  class="tw-text-xl tw-mt-4 tw-mb-2"
                >
                  Note:
                </div>
                <v-textarea
                  :disabled="!isActionEnabled && supplier.status != 0"
                  v-if="supplier.status != 8"
                  filled
                  v-model="message"
                ></v-textarea>
              </div>
              <v-card-actions class="tw-flex-1 tw-flex tw-items-end">
                <v-spacer></v-spacer>
                <v-btn
                  @click="archiveQuotation(8)"
                  text
                  v-if="user.role != 'supplier' && supplier.status != 8"
                  class="!tw-text-orange"
                  >Archivia</v-btn
                >
                <v-btn
                  @click="disputeDraft"
                  text
                  v-if="
                    user.role != 'supplier' &&
                    supplier.status == 4 &&
                    isActionEnabled
                  "
                  class="!tw-text-custom-red"
                  >Contesta</v-btn
                >

                <v-btn color="gray darken-1" text @click="isVisible = false">
                  Annulla
                </v-btn>
                <v-btn
                  @click="updateQuotation(supplier.status, false, true)"
                  text
                  v-if="
                    user.role == 'supplier' &&
                    supplier.status == 3 &&
                    isActionEnabled 
                    && quotation.type == 'preventivi_stampa'
                  "
                  color="blue darken-1"
                  class="!tw-text-custom-red"
                  >Salva (senza avanzare)</v-btn
                >
                <v-btn
                  v-if="
                    (isActionEnabled &&
                      supplier.status != 7 &&
                      supplier.status != 8) ||
                    supplier.status == 0 ||
                    (supplier.status == 2 && user.role != 'supplier')
                  "
                  @click="updateQuotation(supplier.status + 1)"
                  color="green darken-1"
                  text
                >
                  {{ getButtonLabel(supplier.status) }}
                </v-btn>
                <v-btn
                  v-if="supplier.status == 5 && user.role != 'supplier'"
                  @click="updateQuotation(supplier.status + 1)"
                  color="green darken-1"
                  text
                >
                  {{ getButtonLabel(supplier.status) }}
                </v-btn>
              </v-card-actions>
            </v-card>
            <div class="tw-w-1/2 !tw-overflow-y-scroll" id="timeline">
              <SupplierTimeline
                v-if="isTimelineVisible"
                :timeline="supplier.timeline"
                @scrollToBottom="scrollToBottom"
              />
            </div>
          </div>
          <v-card
            v-else
            class="!tw-h-64 !tw-flex !tw-justify-center !tw-items-center"
          >
            <v-progress-circular
              indeterminate
              color="#2F2F2F"
            ></v-progress-circular>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div v-else-if="action == 'view'">
      <div>
        <v-icon
          @click="isVisible = true"
          class="tw-cursor-pointer hover:tw-opacity-80 hover:tw-text-custom-blue"
          >mdi-eye</v-icon
        >
        <v-dialog v-model="isVisible" persistent max-width="800px">
          <v-card>
            <v-card-title> </v-card-title>
            <v-card-text>
              <h3 class="tw-text-3xl tw-font-bold !tw-text-dark">
                Recap Preventivo
              </h3>
            </v-card-text>
            <div class="tw-px-6">
              <div class="tw-text-xl">
                Stampatore: {{ quotation.machine_supplier }}
              </div>
              <div v-if="quotation.rif_code" class="tw-text-xl">
                Codice commessa: {{ quotation.rif_code }}
              </div>
              <div class="tw-text-xl">Materiale: {{ quotation.tp }}</div>
              <div class="tw-text-xl">Resa: {{ quotation.resa }}</div>
              <div
                v-if="supplier.icaFiles.length > 0"
                class="tw-text-xl tw-mt-4 tw-mb-2"
              >
                Master Preventivo:
              </div>
              <div v-if="files.length == 0">
                Non ci sono file da visualizzare
              </div>
              <FileItem
                @file-selected="handleFileSelection"
                :_id="_id"
                v-for="(file, index) in supplier.icaFiles"
                :key="index"
                :fname="file"
                path="ica"
              />
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="isVisible = false">
                Chiudi
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <response-notification></response-notification>
  </div>
</template>

<script>
import FileItem from "../FileItem.vue";
import SupplierTimeline from "./supplierTimeline.vue";
import axios from "../../plugins/axios";
import { mapQuotationStatus } from "../../plugins/utils";

export default {
  components: { FileItem, SupplierTimeline },
  name: "supplierModal",
  props: [
    "read_only",
    "supplier",
    "quotation",
    "supplierIndex",
    "quotationIndex",
    "files",
    "_id",
    "_draftFiles",
    "action",
    "isActionEnabled",
    "index"
  ],
  data() {
    return {
      example: ["file", "file2", "file3"],
      isResetting: false,
      newFiles: [],
      supplierActions: [1, 3, 5],
      isTimelineVisible: false,
      message: "",
      fileLinks: [],
      fileType: "file",
      defFiles: [],
      isLoading: false,
      fileErrors: [],
      draftFiles: [],
      ddtFiles: [],
      isVisible: false,
      quotationFiles: new FormData(),
      selectedFiles: [],
      rules: [
        (value) =>
          !value ||
          value.size < 31457280 ||
          "Il file deve essere inferiore a 30 MB, se superiore allega nelle note un link di We stransfer o altri provider",
      ],
    };
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        this.isTimelineVisible = true;
      } else {
        //reset data
        this.ddtFiles = [];
        this.draftFiles = [];
        this.defFiles = [];
        this.fileLinks = [];
        this.newFiles = [];
        this.defFiles = []
        this.message = ""
        this.isTimelineVisible = false; 
        this.quotationFiles = new FormData();
        this.selectedFiles = []
        this.isResetting = false
        this.isTimelineVisible = false
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    filesListString: {
      get() {
        let filesArray = [];

        if (this.supplier.status == 2) filesArray = this.defFiles;
        else if (this.supplier.status == 3) filesArray = this.draftFiles;
        else if (this.supplier.status == 5) filesArray = this.ddtFiles;

        // Map over the selected files array and extract the name property
        return filesArray.map((file) => file.name);
      },
    },
  },
  methods: {
    scrollToBottom() {
      try {
        let timeline = document.getElementById("timeline");
        timeline.scrollTop = timeline.scrollHeight;
      } catch (error) {
        console.log(error);
      }
    },
    viewFile(item) {
      let oldValueFiles;
      if (this.supplier.status == 2) oldValueFiles = this.defFiles;
      else if (this.supplier.status == 3) oldValueFiles = this.draftFiles;
      else if (this.supplier.status == 5) oldValueFiles = this.ddtFiles;

      let oldValueFileNames = oldValueFiles.map((fileObj) => fileObj.name);

      if (oldValueFileNames.includes(item)) {
        let selectedFile = oldValueFiles.find(
          (fileObj) => fileObj.name === item
        );
        console.log(selectedFile);

        if (selectedFile) {
          const fileURL = URL.createObjectURL(selectedFile);
          window.open(fileURL, "_blank");
        }
      }
    },
    async disputeDraft() {
      try {
        const res = await this.$confirm(
          `Sei di voler contestare la bozza? ${
            !this.message ? "<br>Non hai inserito alcun messaggio" : ""
          }`
        );
        if (!res) {
          return;
        }
      } catch (error) {
        // Handle rejection or error from the confirm dialogue here
        return;
      }
      this.updateQuotation(this.supplier.status - 1, true);
    },
    onInput(newValue) {
      let oldValueFiles;
      if (this.supplier.status == 2) oldValueFiles = this.defFiles;
      else if (this.supplier.status == 3) oldValueFiles = this.draftFiles;
      else if (this.supplier.status == 5) oldValueFiles = this.ddtFiles;

      let oldValueFileNames = oldValueFiles.map((fileObj) => fileObj.name);

      if (oldValueFileNames.includes(newValue)) {
        let updatedFiles = oldValueFiles.filter(
          (fileObj) => fileObj.name !== newValue
        );

        if (this.supplier.status == 2) this.defFiles = updatedFiles;
        else if (this.supplier.status == 3) this.draftFiles = updatedFiles;
        else if (this.supplier.status == 5) this.ddtFiles = updatedFiles;
      }
    },

    getDialogTitle(state) {
      switch (state) {
        case 0:
          return "Richiedi preventivo";
        case 1:
          return "Nuovo preventivo";
        case 2:
          return "Conferma impianto";
        case 3:
          return "Conferma ordine";
        case 4:
          return "Conferma bozza";
        case 5:
          return "Conferma spedizione";
        case 6:
          return "Conferma ricezione IS";
        case 7:
          return "Ordine completato";
        default:
          return "";
      }
    },
    getButtonLabel(state) {
      switch (state) {
        case 0:
          return "Invia";
        case 1:
          return "Salva";
        case 2:
          return "Conferma";
        case 3:
          return "Conferma";
        case 4:
          return "Conferma";
        case 5:
          return "Conferma";
        case 6:
          return "Conferma";
        case 7:
          return "Conferma";
        default:
          return "Conferma";
      }
    },
    validateFiles(isDraft = false) {
      const urlPattern = /^(http|https):\/\/[^ "]+$/;
      if (this.fileType == "file") {
        let result = true;
        if (this.supplier.status == 0 && this.selectedFiles.length == 0)
          result = false;
        if (this.supplier.status == 2 && this.defFiles.length == 0)
          result = false;
        if (
          this.supplier.status == 3 &&
          this.draftFiles.length == 0 &&
          !isDraft &&
          this.supplier.draftFiles.length == 0
        )
          result = false;
        if (this.supplier.status == 5 && this.ddtFiles.length == 0 && this.user.role == "supplier")
          result = false;
        if (!result) {
          this.$store.dispatch("notificate", {
            text: "Non hai allegato alcun file",
            type: "warning",
          });
        }
        return result;
      } else if (this.fileType == "link") {
        if (this.fileLinks.length == 0) {
          this.$store.dispatch("notificate", {
            text: "Non hai inserito alcun link",
            type: "warning",
          });
          return false;
        } else {
          for (const link of this.fileLinks) {
            if (!urlPattern.test(link)) {
              this.$store.dispatch("notificate", {
                text: "Link non valido",
                type: "warning",
              });
              return false;
            }
          }
        }
        return true;
      }
    },
    checkLinkValidity() {
      this.invalidLinks = this.fileLinks.filter((link) => {
        return !link.startsWith("http://") && !link.startsWith("https://");
      });

      if (this.invalidLinks.length > 0) {
        this.$store.dispatch("notificate", {
          text: "Link non valido",
          type: "warning",
        });
        this.fileLinks = this.fileLinks.filter((link) => {
          return link.startsWith("http://") || link.startsWith("https://");
        });
      }
    },
    _mapQuotationStatus(item) {
      return mapQuotationStatus(item);
    },
    handleFileChange(e) {
      // If the change event was triggered by the reset action, just return
      if (this.isResetting) {
        this.isResetting = false;
        return;
      }
      let files;

      if (this.supplier.status == 2) files = this.defFiles;
      else if (this.supplier.status == 3) files = this.draftFiles;
      else if (this.supplier.status == 5) files = this.ddtFiles;

      this.fileErrors = [];

      if (e.length > 0) {
        for (const file of e) {
          if (file.size > 31457280) {
            this.fileErrors.push(file);
          }
        }
      }

      if (this.fileErrors.length > 0) {
        this.$store.dispatch("notificate", {
          text: "File troppo grande, allega nelle note un link di WeTransfer o altri provider",
          type: "warning",
        });
        this.$refs.fileInput.reset();
        return;
      }

      // ... rest of your code
      if (this.supplier.status == 2) this.defFiles = this.defFiles.concat(e);
      else if (this.supplier.status == 3)
        this.draftFiles = this.draftFiles.concat(e);
      else if (this.supplier.status == 5)
        this.ddtFiles = this.ddtFiles.concat(e);
      // Before resetting the input, set the isResetting flag to true
      this.isResetting = true;
      this.$refs.fileInput.reset();
    },
    handleFileSelection(data) {
      if (data.action) this.selectedFiles = [...this.selectedFiles, data.file];
      else
        this.selectedFiles = this.selectedFiles.filter(
          (item) => item !== data.file
        );
    },
    async archiveQuotation(newStatus) {
      this.$store.dispatch("archiveQuotation", this.supplier);
      if (newStatus == 8) {
        try {
          const res = await this.$confirm(
            "Sei sicuro di voler archiviare l'ordine?"
          );
          if (!res) {
            return;
          }
        } catch (error) {
          // Handle rejection or error from the confirm dialogue here
          return;
        }
      }
      this.isLoading = true;
      const formData = new FormData();
      let supplierCopy = { ...this.supplier };
      let quotation = { ...this.quotation };
      supplierCopy["status"] = newStatus;

      const dataToSend = {
        quotation: quotation,
        supplier: supplierCopy,
        existingFiles: this.selectedFiles,
        isDisputed: this.isDisputed,
        message: this.message,
      };

      Object.entries(dataToSend).forEach(([key, value]) => {
        formData.append(
          key,
          typeof value === "object" ? JSON.stringify(value) : value
        );
      });

      axios
        .patch(
          `quotation/${this._id}/${this.quotation.salted_id}/${this.supplier.salted_id}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((res) => {
          this.$store.dispatch("notificate", {
            text: "Preventivo archiviato correttamente",
            type: "success",
          });
          this.isVisible = false;
          supplierCopy = this.supplier;
          supplierCopy["status"] = newStatus;
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$store.dispatch("notificate", {
              text: err.response.data.error,
              type: "error",
            });
          } else {
            this.$store.dispatch("notificate", {
              text: "C'è stato un errore, contatta un amministratore",
              type: "error",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async updateQuotation(newStatus, isDisputed = false, isDraft = false) {
      if (this.validateFiles(isDraft)) {
        if (newStatus == 1) {
          try {
            const res = await this.$confirm(
              "Sei sicuro di voler inviare il preventivo al fornitore?"
            );
            if (!res) {
              return;
            }
          } catch (error) {
            // Handle rejection or error from the confirm dialogue here
            return;
          }
        }
        if (
          (newStatus == 2 ||
            (newStatus == 4 &&
              this.supplier._type == "order" &&
              this.supplier.price == 0 &&
              !isDraft)) &&
          (!this.supplier.price || this.supplier.price <= 0)
        ) {
          this.$store.dispatch("notificate", {
            text: "Non hai inserito un prezzo",
            type: "warning",
          });
          return;
        }

        if (this.fileErrors.length > 0) {
          this.$store.dispatch("notificate", {
            text:
              "File " +
              this.fileErrors +
              " grande, allega nelle note un link di We stransfer o altri provider",
            type: "warning",
          });
          return;
        }

        let quotation = { ...this.quotation };
        let supplierCopy = { ...this.supplier };
        supplierCopy["status"] = newStatus;
        if (newStatus == 1) {
          let newFiles = [];
          if (this.fileType == "link") {
            this.fileLinks.forEach((item) => {
              newFiles.push({ name: item, type: "link", size: 0 });
            });
          }
          supplierCopy.icaFiles =
            this.fileType == "file" ? this.selectedFiles : newFiles;
        }

        const formData = new FormData();
        if (newStatus == 3 && !isDisputed) {
          let newFiles = [];
          if (this.fileType == "link") {
            this.fileLinks.forEach((item) => {
              newFiles.push({ name: item, type: "link", size: 0 });
            });

            supplierCopy.defFiles = newFiles;
          } else {
            for (const file of this.defFiles) {
              formData.append("defFiles", file);
            }
          }
        }
        console.log(this.draftFiles);
        if (newStatus == 4 || (newStatus == 3 && isDraft && !isDisputed)) {
          let newFiles = [];
          if (this.fileType == "link") {
            this.fileLinks.forEach((item) => {
              newFiles.push({ name: item, type: "link", size: 0 });
            });

            supplierCopy.draftFiles = newFiles;
          } else {
            for (const file of this.draftFiles) {
              formData.append("draftFiles", file);
            }
          }
        }
        if (newStatus == 6) {
          for (const file of this.ddtFiles) {
            formData.append("ddtFiles", file);
          }
        }

        if (
          Object.prototype.hasOwnProperty.call(
            supplierCopy,
            "isSupplierActions"
          ) &&
          supplierCopy.isSupplierActions === true
        ) {
          // If the condition is met, remove the key from the object
          delete supplierCopy.isSupplierActions;
        }

        const dataToSend = {
          quotation: quotation,
          supplier: supplierCopy,
          existingFiles: this.selectedFiles,
          isDisputed: isDisputed,
          message: this.message,
          isDraft: isDraft,
        };

        Object.entries(dataToSend).forEach(([key, value]) => {
          formData.append(
            key,
            typeof value === "object" ? JSON.stringify(value) : value
          );
        });
        this.isLoading = true;
        axios
          .patch(
            `quotation/${this._id}/${this.quotation.salted_id}/${this.supplier.salted_id}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((res) => {
            this.$store.dispatch("notificate", {
              text: "Preventivo aggiornato correttamente",
              type: "success",
            });
            this.isVisible = false;
            supplierCopy = this.supplier;
            supplierCopy["status"] = newStatus;
          })
          .catch((err) => {
            this.$store.dispatch("notificate", {
              text: "C'è stato un errore, contatta un amministratore",
              type: "error",
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
/* .v-dialog__content--active {
  justify-content: flex-start !important;
  margin-left: 36%;
}

@media (max-width: 2200px) and (min-width: 900px) {
  .v-dialog__content--active {
    margin-left: 18%;
    width: 600px;
  }
}

@media (max-width: 1600px) and (min-width: 769px) {
  .v-dialog__content--active {
    margin-left: 0;
    width: 600px;
  }
}

@media (max-width: 768px) and (min-width: 577px) {
  .v-dialog__content--active {
    margin-left: 3%;
  }
}

@media (max-width: 576px) {
  .v-dialog__content--active {
    margin-left: 2%;
  }
} */
</style>