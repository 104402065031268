import store from '../store'

export const checkForm = (classname) => {
  var forms = document.getElementsByClassName(classname);
  for (var i = 0; i < forms.length; i++) {
    if (!forms[i].reportValidity()) {
      return false;
    }
  }
  return true;

}

export const mapPriorityStatus = (status) => {
  switch (status) {
    case 0:
      return "scaduto"
    case 1:
      return "ordine in corso"
    case 2:
      return "urgente"
    case 3:
      return "standard"
    case 4:
      return "non urgente"
  }
}


export const mapPriorityStatusReverse = (label) => {
  switch (label) {

    case "scaduto":
      return 0;
    case "ordine in corso":
      return 1;
    case "urgente":
      return 2;
    case "standard":
      return 3;
    case "non urgente":
      return 4;
    default:
      return -1; // Return -1 or handle the unknown label case as per your requirements
  }
};


export const mapQuotationStatus = (item) => {
  const userRole = store.state.currentUser.role;
  let status = item.status;

  let label = "Stato sconosciuto";
  let color = "#FF0000";


  switch (status) {
    case 0:
      label = "In attesa di invio";
      color = "#E0E0E0";
      break;
    case 1:
      label = userRole === 'supplier' ? "Completa preventivo" : "In attesa del prezzo";
      color = userRole === 'supplier' ? "red" : "#ffb400";
      break;
    case 2:
      label = userRole === 'supplier' ? "In attesa conferma ordine" : "Conferma ordine";
      color = userRole === 'supplier' ? "#ffb400" : "red";
      break;
    case 3:
      label = userRole === 'supplier' ? "Inserisci bozza" : "Caricamento bozza in corso";
      color = userRole === 'supplier' ? "red" : "#ffb400";
      break;
    case 4:
      label = userRole === 'supplier' ? "In attesa conferma bozza" : "Conferma bozza";
      color = userRole === 'supplier' ? "#ffb400" : "red";
      break;
    case 5:
      label = userRole === 'supplier' ? "Conferma spedizione" : "IS in attesa di spedizione";
      color = userRole === 'supplier' ? "red" : "#ffb400";
      break;
    case 6:
      label = userRole === 'supplier' ? "In attesa ricezione" : "Conferma ricezione";
      color = userRole === 'supplier' ? "#ffb400" : "red";
      break;
    case 7:
      label = "Visualizza";
      color = "green"; // Gray color for case 7
      break;
    case 8:
      label = "Archiviato";
      color = "gray"; // Gray color for case 8
      break;
  }

  return { label, color };
};

