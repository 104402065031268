<template>
  <v-dialog
    v-model="isVisible"
    content-class="!tw-bg-white"
    scrollable
    max-height="600px"
    width="800px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="!tw-text-white !tw-bg-custom-blue !tw-rounded-xl tw-ml-4"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Nuovo
      </v-btn>
    </template>
    <v-card>
      <form
        v-if="type === 'preventivi_fustella'"
        class="tw-full tw-flex tw-flex-wrap tw-box-border tw-p-8 font2 quotation-form"
      >
        <div class="tw-text-xl tw-font-bold tw-mb-8">
          Nuovo Preventivo Fustella
        </div>
        <div class="tw-flex tw-items-center tw-w-full tw-mb-4">
          <div>Fustellatore</div>
          <div class="tw-flex-1 tw-ml-4">
            <v-select
              filled
              placeholder="Seleziona il fustellatore"
              label="Seleziona il fustellatore"
              :items="machineSuppliers"
              @change="updateSelectedSuppliers('preventivi_fustella')"
              required
              item-text="username"
              v-model="fustella_model.machine_supplier"
            ></v-select>
          </div>
        </div>
        <div v-if="fustella_model.suppliers.length > 0" class="tw-w-full tw-mb-4">
          <div class="tw-flex tw-flex-col">
            <div class="tw-text-lg tw-font-bold tw-mb-2 tw-mt-2">
              Verranno generati i seguenti preventivi:
            </div>
            <GeneratedSupplier
              @deletedSupplier="deleteGeneratedSupplier(quotation, 'preventivi_fustella')"
              :item="quotation"
              v-for="(quotation, index) in fustella_model.suppliers"
              :key="index"
              :canDelete="isNew"
            />
          </div>
        </div>
        <div class="tw-mb-4 tw-w-full">
          <v-select
            placeholder="Seleziona il tipo di macchina"
            :items="['Piana', 'Rotativa', 'Casemaker', 'Platina']"
            required
            filled
            v-model="fustella_model.tp"
          ></v-select>
        </div>
        <div class="tw-mb-4 tw-w-full">
          <v-select
            placeholder="Seleziona la resa"
            :items="resaOptions"
            required
            filled
            v-model="fustella_model.resa"
          ></v-select>
        </div>
        <div class="tw-mb-4 tw-w-full" v-if="isNew">
          <v-text-field
            placeholder="Inserisci Codice fornitore"
            label="Codice fornitore"
            filled
            v-model="fustella_model.rif_code"
          ></v-text-field>
        </div>
      </form>

      <form
        v-else-if="type === 'preventivi_stampa'"
        class="tw-full tw-flex tw-flex-wrap tw-box-border tw-p-8 font2 quotation-form"
      >
        <div class="tw-flex tw-w-full tw-items-center tw-mb-8">
          <div class="tw-text-xl tw-font-bold">
            Nuovo Preventivo Impianto Stampa
          </div>
          <div class="tw-flex-1" v-if="isNew">
            <v-radio-group
              row
              v-model="quotationRequestType"
              class="!tw-mt-6 !tw-mb-0 !tw-pt-0 !tw-ml-2"
            >
              <v-radio label="Preventivo" value="quotation"></v-radio>
              <v-radio label="Ordine" value="order"></v-radio>
            </v-radio-group>
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-w-full tw-mb-4">
          <div>Stampatore</div>
          <div class="tw-flex-1 tw-ml-4">
            <v-select
              filled
              placeholder="Seleziona lo stampatore"
              label="Seleziona il fornitore"
              :items="machineSuppliers"
              @change="updateSelectedSuppliers('preventivi_stampa')"
              required
              item-text="username"
              v-model="stampa_model.machine_supplier"
            />
          </div>
        </div>
        <div v-if="stampa_model.suppliers.length > 0" class="tw-w-full tw-mb-4">
          <div class="tw-flex tw-flex-col">
            <div class="tw-text-lg tw-font-bold tw-mb-2 tw-mt-2">
              Verranno generati i seguenti preventivi:
            </div>
            <GeneratedSupplier
              @deletedSupplier="deleteGeneratedSupplier(quotation, 'preventivi_stampa')"
              :item="quotation"
              v-for="(quotation, index) in stampa_model.suppliers"
              :key="index"
              :canDelete="isNew"
            />
          </div>
        </div>
        <div class="tw-mb-4 tw-w-full">
          <v-select
            placeholder="Seleziona il Materiale"
            :items="['Solido Analogico', 'Solido Digitale', 'Liquido']"
            required
            filled
            v-model="stampa_model.tp"
          ></v-select>
        </div>
        <div class="tw-mb-4 tw-w-full">
          <v-select
            placeholder="Seleziona la resa"
            :items="resaOptions"
            required
            filled
            v-model="stampa_model.resa"
          ></v-select>
        </div>
        <div class="tw-mb-4 tw-w-full" v-if="isNew">
          <v-text-field
            placeholder="Inserisci Codice commessa"
            label="Codice commessa"
            filled
            v-model="stampa_model.rif_code"
          ></v-text-field>
        </div>
        <div class="tw-mb-4 tw-w-full" v-if="isNew && quotationRequestType === 'order'">
          <v-text-field
            placeholder="Inserisci Prezzo"
            label="Prezzo"
            filled
            type="number"
            v-model="stampa_model.price"
          ></v-text-field>
        </div>
      </form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="isVisible = false">
          Annulla
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="isLoading"
          @click="createQuotation(type)"
        >
          Crea
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "../../plugins/axios";
import GeneratedSupplier from "./generatedSupplier.vue";

export default {
  name: "CreationModal",
  components: {
    GeneratedSupplier,
  },
  props: {
    machineSuppliers: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      quotation_files: new FormData(),
      quotationRequestType: "quotation",
      isLoading: false,
      isVisible: false,
      fustella_model: this.initializeModel("preventivi_fustella"),
      stampa_model: this.initializeModel("preventivi_stampa"),
      resaOptions: [
        "Casemaker",
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
      ],
    };
  },
  methods: {
    initializeModel(type) {
      return {
        machine_supplier: "",
        tp: "",
        resa: 0,
        referrer_notes: "",
        suppliers: [],
        is_saved: 0,
        has_sent: 0,
        status: 0,
        delegate: "",
        ref: "",
        type: type,
        rif_code: "",
        price: 0,
      };
    },
    validateRifCode(type) {
      const model = this.getModel(type);
      const selectedMachineSupplier = this.machineSuppliers.find(
        (supplier) => supplier.username === model.machine_supplier
      )
      if (type === "preventivi_stampa" && selectedMachineSupplier.rif_stampa_required) {
        return true
      }
      
      if (type === "preventivi_fustella" && selectedMachineSupplier.rif_fustella_required) {
        return true
      }
      return false
    },
    validateQuotation(type) {
      const model = this.getModel(type);
      if (!model) {
        console.error(`Model for type "${type}" is not defined.`);
        return false;
      }
      const requiredFields = ["machine_supplier", "tp", "resa", "suppliers"];
      if (this.validateRifCode(type)) {
        requiredFields.push("rif_code")
      }
      for (let field of requiredFields) {
        if (!model[field]) {
          this.$store.dispatch("notificate", {
            text: "Compila tutti i campi",
            type: "warning",
          });
          return false;
        }
      }
      if (
        this.isNew &&
        type === "preventivi_stampa" &&
        this.quotationRequestType === "order" &&
        model.suppliers.length > 1
      ) {
        this.$store.dispatch("notificate", {
          text: "Seleziona solo un fornitore per l'ordine diretto",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    deleteGeneratedSupplier(item, type) {
      const model = this.getModel(type);
      console.log(model.suppliers.indexOf(item))
      model.suppliers.splice(model.suppliers.indexOf(item), 1);
    },
    createQuotation(type) {
      if (!this.validateQuotation(type)) return;

      const model = this.getModel(type);
      model.delegate = this.project.delegate;
      model.ref = this.project.referrer;
      model.requestType = this.quotationRequestType;

      this.quotation_files.append("quotation", JSON.stringify(model));
      const endpoint = `${this.isNew ? "" : "old-"}quotation/${
        this.project._id
      }/${type}`;

      axios
        .post(endpoint, this.quotation_files)
        .then((response) => {
          this.$emit("quotationCreated", {
            quotation: response.data.quotation,
            quotationType: type,
            isNew: this.isNew,
          });
          this.$store.dispatch("notificate", {
            text: response.data.msg,
            type: "success",
          });
          this.quotation_files = new FormData();
          this.isLoading = false;
          this.isVisible = false;
        })
        .catch(() => {
          this.$store.dispatch("notificate", {
            text: "Errore durante la creazione della quotazione",
            type: "error",
          });
          this.isLoading = false;
        });
    },
    updateSelectedSuppliers(type) {
      const model = this.getModel(type);
      const selectedSupplier = model.machine_supplier;
      const machineSupplier = this.machineSuppliers.find(
        (supplier) => supplier.username === selectedSupplier
      );

      if (machineSupplier && machineSupplier.suppliers) {
        model.suppliers = machineSupplier.suppliers
          .filter((supplier) => supplier.supplier_type === this.type)
          .map((supplier) => ({
            supplier: supplier.username,
            price: 0,
            status: 0,
            has_sent: 0,
            is_editing: 0,
            notes: "",
            master_notes: "",
            id: supplier.id,
          }));
      } else {
        model.suppliers = [];
      }
    },
    getModel(type) {
      return type === 'preventivi_fustella' ? this.fustella_model : this.stampa_model;
    }
  },
  watch: {
    type: {
      immediate: true,
      handler(newType) {
        this.fustella_model = this.initializeModel("preventivi_fustella");
        this.stampa_model = this.initializeModel("preventivi_stampa");
      },
    },
  },
};
</script>

<style scoped>
.tw-mb-4 {
  margin-bottom: 1rem;
}
</style>
